import React from "react"
import Layout from "components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "components/SEO"

const about = () => {
  return (
    <div>
      <Seo
        title="About Us | High Quality Live Mealworms for Pets"
        description="About Us - High Quality Live Mealworms
With high nutritional properties and fibre. Mealworms are perfect to feed reptiles , birds, small rodents and fish"
      />
      <Layout>
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
          <div className="lg:w-10/12 w-full">
            <h2 className="xl:w-8/12 lg:w-10/12 w-full font-bold text-gray-800 lg:text-4xl text-3xl lg:leading-10 leading-9 mt-2">
              A Bug's Life and
              <span className="block text-lime-600 xl:inline">
                {" "}
                Insect Farming
              </span>
            </h2>

            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Early in 2019 I saw a Facebook post from an old work mate saying
              that she had thousands of tadpoles in her swimming pool that had
              to go so that she could clean the pool and get it ready to use. I
              arranged to take the kids to catch a few tadpoles on Saturday
              morning. They thoroughly enjoyed the day and didn’t want to stop
              catching them - we left with 62 tadpoles!
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Although we gave some away, it wasn’t long before we found
              ourselves with 27 little frogs to feed. I quickly learnt that
              catching enough flies to feed them was going to be a very time
              consuming exercise. I did some research and discovered insect
              farming and decided to give it a go. I soon had my very own
              mealworm farm growing in the hot water cupboard.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              After the first Covid19 lockdown in 2020 I explored ways to turn
              this small hobby into a business. I am pleased to say that after a
              few headaches and trialling different options, I successfully set
              up an area for the insects and was able to give my wife her hot
              water cupboard back.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Since then I have been selling insects via Trade me when I had
              more than I needed for our frogs and my customer base increased
              through ‘word-of-mouth’. By late 2021 we were ready to launch
              Mandatory Insects so that customers can order direct from us via
              the new website.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Supporting Mandatory Insects is supporting a local Auckland family
              and helping to fund activities for our two young children who have
              enjoyed learning about insect farming, showing more interest in
              the insects than they do in the pet frogs that the insects are
              bred to feed. I would like to thank you for taking the time to get
              to know a little about us.
            </p>
            <h4 className="font-normal text-xl leading-6 text-gray-600 mt-6">
              Matt Upson
            </h4>
            <span className="text-lime-700">Mandatory Insects</span>
          </div>

          <div className="lg:mt-14 sm:mt-10 mt-12">
            <StaticImage
              className="lg:block w-full max-w-lg"
              src="../components/images/website/About/family-photo.jpg"
              alt="Mealworms"
              layout="fullWidth"
              placeholder="blurred"
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default about
